.color-pallete {
  width: 100vw;
  height: 12vh;
  display: flex;
  justify-content: center;
  padding: 3% 0 0 0;
  flex-wrap: wrap;
}
:root {
  --size: 32px;
}
.color-pallete > div {
  width: var(--size);
  height: var(--size);
  margin: 0 10px 0 10px;
  position: relative;
}
.color-pallete > div > button {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  outline: none;
  border: 2px solid #000;
  box-shadow: 0px 3px 0px #000;
  transition: all 200ms;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.color-pallete > div > button.active {
  opacity: 0.5;
}
.color-pallete > div > button:not(.active):hover {
  box-shadow: 0px 5px 0px #000;
  transform: translateY(-2px);
}

/* .color-pallete > div > button::after {
  content: "";
  display: block;
  width: 102%;
  height: 102%;

  border-radius: 20px;
} */
