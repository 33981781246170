@import "https://fonts.googleapis.com/css?family=Fira+Mono";
@import "https://fonts.googleapis.com/css?family=Just+Me+Again+Down+Here";
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-family: "myartboxfont", "Fira Mono", "Courier New", Courier, monospace;
}
body {
  width: 100vw;
  height: 100vh;
}
#root {
  width: 100vw;
  height: 100vh;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
